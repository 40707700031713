import React, { useCallback, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Button, Dropdown, Modal, Table } from "semantic-ui-react";

import "./Perfil.scss";
import { useSelector } from "react-redux";
import {
	useGetProfileQuery,
	useUploadProfileFileMutation,
} from "../../../store/profile/ProfileApi";
import { toast } from "react-toastify";

const tiposImagenesCaptura = [
	{
		text: "Constancia de situación fiscal",
		value: "constancia_situacion_fiscal",
	},
	{
		text: "Logotipo propio",
		value: "logo",
	},
];

const DetallesPerfil = ({ setEdit }) => {
	const [abrirModal, setAbrirModal] = useState(false);
	const [selectedFile, setSelectedFile] = useState(null);
	const [tipoImagen, setTipoImagen] = useState("");
	const [uploadProfileFile] = useUploadProfileFileMutation();

	const navigate = useNavigate();

	const user_id = useSelector((state) => state.auth.id);

	const { data: profile = "" } = useGetProfileQuery(user_id, {
		refetchOnMountOrArgChange: true,
	});

	const handleAbrirModal = () => {
		setSelectedFile(null);
		setTipoImagen("");
		setAbrirModal(true);
	};

	const handleImageTypeChange = (_e, data) => {
		setTipoImagen(data.value);
	};

	const onFileChange = (e) => {
		setSelectedFile(e.target.files[0]);
	};

	const handleCancelUpload = () => {
		setAbrirModal(false);
	};

	const notifySaved = useCallback(() => {
		toast.success("Archivo guardado");
	}, []);

	const handleFileUpload = () => {
		setAbrirModal(false);
		const fileType = selectedFile.type;
		if (
			fileType === "application/pdf" ||
			fileType === "image/jpeg" ||
			fileType === "image/png" ||
			fileType === "image/gif"
		) {
			uploadProfileFile({
				id: user_id,
				tipoImagen,
				image: selectedFile,
			}).then(() => {
				notifySaved();
			});
			setSelectedFile(null);
		} else {
			alert("Seleccione una imágen válida");
		}
		setSelectedFile(null);
	};

	const obtenerTipoLogo = (value) => {
		if (value === "logoATiempo") {
			return "Logo A Tiempo Planea";
		} else if (value === "logoPropio") {
			return "Logo Propio";
		} else {
			return "Sin Logo";
		}
	};

	const ModalCapturaImagen = () => (
		<Modal
			open={abrirModal}
			size="tiny"
			className="modal"
			closeOnDimmerClick={false}
		>
			<Modal.Header>Captura de imagen</Modal.Header>
			<Modal.Content>
				<Dropdown
					placeholder="Selecciona el tipo de imagen"
					onChange={handleImageTypeChange}
					selection
					options={tiposImagenesCaptura}
					value={tipoImagen}
					className="dropdown"
				/>
				<div>
					<label
						htmlFor="hidden-new-file"
						className="ui icon button input-file"
					>
						<i className="cloud icon"></i> Seleccionar archivo
					</label>
					<input
						type="file"
						id="hidden-new-file"
						className="input-button"
						onChange={onFileChange}
					/>
				</div>
				<div className="botonera">
					<Button className="ui button" onClick={handleCancelUpload}>
						Cancelar
					</Button>
					<Button
						className={`ui button ${
							selectedFile && tipoImagen ? "upload-button" : ""
						}`}
						id={`${
							selectedFile && tipoImagen ? "upload-button" : "disabled-button"
						}`}
						onClick={handleFileUpload}
						disabled={!selectedFile || !tipoImagen}
					>
						Subir
					</Button>
				</div>
			</Modal.Content>
		</Modal>
	);

	return (
		<>
			<div className="subsubheader">Detalles</div>
			<Table striped className="perfil-table">
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>Campo</Table.HeaderCell>
						<Table.HeaderCell>Valor</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					<Table.Row>
						<Table.Cell>Tipo de persona</Table.Cell>
						<Table.Cell>{profile?.tipo_persona}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>RFC</Table.Cell>
						<Table.Cell>{profile?.rfc}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>Razón social</Table.Cell>
						<Table.Cell>{profile?.razon_social}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>Código postal</Table.Cell>
						<Table.Cell>{profile?.codigo_postal}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>Régimen fiscal</Table.Cell>
						<Table.Cell>{profile?.regimen_fiscal}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>Correo electrónico</Table.Cell>
						<Table.Cell>{profile?.email}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>Uso de CFDI</Table.Cell>
						<Table.Cell>{profile?.uso_cfdi}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>Tipo de logo</Table.Cell>
						<Table.Cell>{obtenerTipoLogo(profile?.tipo_logo)}</Table.Cell>
					</Table.Row>
				</Table.Body>
			</Table>

			<div id="botonera1">
				<Button onClick={handleAbrirModal}>Captura de imágenes</Button>
				<NavLink to={`/configuracion/verImagenes?id=${user_id}&tipo=perfil`}>
					<Button>Ver imágenes</Button>
				</NavLink>
			</div>
			<br />
			<ModalCapturaImagen />
			<div id="botonera">
				<Button
					type="button"
					id="secondary-button"
					onClick={() => navigate(-1)}
				>
					Regresar
				</Button>
				<Button type="submit" id="primary-button" onClick={() => setEdit(true)}>
					Editar
				</Button>
			</div>
		</>
	);
};

export default DetallesPerfil;
