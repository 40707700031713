import { NavLink } from "react-router-dom";
import { Button, Container, Divider } from "semantic-ui-react";
import "./Dashboard.scss";
import { urlBaseEstudios } from "../../constants/constants";

const Dashboard = () => {
	return (
		<Container>
			<div className="dashboard">
				<div className="title">Estudios</div>
				<div id="buttons">
					<NavLink to={`${urlBaseEstudios}/captura`}>
						<Button className="button">Captura</Button>
					</NavLink>
					<NavLink to={`${urlBaseEstudios}/validacion`}>
						<Button className="button">Validación</Button>
					</NavLink>
					<NavLink to={`${urlBaseEstudios}/enproceso`}>
						<Button className="button">Proceso</Button>
					</NavLink>
					<NavLink to={`${urlBaseEstudios}/completados`}>
						<Button className="button">Completados</Button>
					</NavLink>
					<NavLink to={`${urlBaseEstudios}/reprocesos`}>
						<Button className="button">Reprocesos</Button>
					</NavLink>
				</div>
				<Divider />
			</div>
		</Container>
	);
};

export default Dashboard;
