import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, Divider, Dropdown, Form } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { preregisterUser } from "../../../../store/auth";
import { NavLink, useNavigate } from "react-router-dom";

import "./Register.scss";
import {
	promotorias,
	tiposLogos,
	waitTime,
} from "../../../../constants/constants";

const Login = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const formik = useFormik({
		initialValues: {
			nombre: "",
			apellido_paterno: "",
			apellido_materno: "",
			whatsapp: "",
			email: "",
			company: "",
			promotoria: "",
			tipo_logo: "logoATiempo",
			// ine: "",
		},
		validationSchema: Yup.object({
			nombre: Yup.string().required("Nombre es requerido"),
			apellido_paterno: Yup.string().required("Apellido paterno es requerido"),
			whatsapp: Yup.string()
				.min(10)
				.max(13)
				.required("Celular / Whatsapp es requerido"),
			email: Yup.string().email().required("Correo electrónico es requerido"),
			promotoria: Yup.string().required("Promotoría es requerida"),
			tipo_logo: Yup.string().required("Debes elegir estilo de logo"),
		}),
		onSubmit: (formData) => {
			dispatch(
				preregisterUser(
					`${formData.nombre}`,
					`${formData.apellido_paterno}`,
					`${formData.apellido_materno}`,
					`${formData.whatsapp}`,
					`${formData.email}`,
					`${formData.company}`,
					`${formData.promotoria}`,
					`${formData.tipo_logo}`
				)
			);
			setTimeout(() => {
				navigate("/");
			}, waitTime);
		},
	});

	return (
		<>
			<Form onSubmit={formik.handleSubmit} className="register">
				<div className="register__subtitle">Registro</div>
				<Form.Input
					type="text"
					placeholder="Juan"
					name="nombre"
					label="Nombre *"
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					value={formik.values.nombre}
					error={formik.touched.nombre && formik.errors.nombre}
					className="register__input"
				/>
				<Form.Input
					type="text"
					placeholder="Pérez"
					name="apellido_paterno"
					label="Apellido paterno *"
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					value={formik.values.apellido_paterno}
					error={
						formik.touched.apellido_paterno && formik.errors.apellido_paterno
					}
					className="register__input"
				/>
				<Form.Input
					type="text"
					placeholder="López"
					name="apellido_materno"
					label="Apellido materno"
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					value={formik.values.apellido_materno}
					error={
						formik.touched.apellido_materno && formik.errors.apellido_materno
					}
					className="register__input"
				/>
				<Form.Input
					type="text"
					placeholder="5512345678"
					name="whatsapp"
					label="Celular / Whatsapp *"
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					value={formik.values.whatsapp}
					error={formik.touched.whatsapp && formik.errors.whatsapp}
					className="register__input"
				/>
				<Form.Input
					type="email"
					placeholder="correo@electronico.com"
					name="email"
					label="Correo electrónico *"
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					value={formik.values.email}
					error={formik.touched.email && formik.errors.email}
					className="register__input"
				/>
				<label htmlFor="promotoria" className="labels">
					Promotoría *
				</label>
				<Dropdown
					name="promotoria"
					id="promotoria"
					placeholder="Selecciona una Promotoría"
					fluid
					selection
					options={promotorias}
					onChange={(_e, data) =>
						formik.setFieldValue("promotoria", data.value)
					}
					onBlur={formik.handleBlur}
					value={formik.values.promotoria}
					error={formik.touched.promotoria && formik.errors.promotoria}
					className="input"
				/>
				<Form.Input
					type="text"
					placeholder="Nombre del despacho"
					name="company"
					label="Despacho"
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					value={formik.values.company}
					error={formik.touched.company && formik.errors.company}
					className="register__input"
				/>
				<label htmlFor="promotoria" className="labels">
					Tipo de logo *
				</label>
				<Dropdown
					name="tipo_logo"
					id="tipo_logo"
					placeholder="Selecciona un tipo de logo"
					fluid
					selection
					options={tiposLogos}
					onChange={(_e, data) => formik.setFieldValue("tipo_logo", data.value)}
					onBlur={formik.handleBlur}
					value={formik.values.tipo_logo}
					error={formik.touched.tipo_logo && formik.errors.tipo_logo}
					className="input"
				/>
				{/* <Form.Field className="register__input">
					<Radio
						label="Con Logo de A Tiempo Planea"
						name="tipo_logo"
						value="logoATiempo"
						checked={formik.values.tipo_logo === "logoATiempo"}
						onChange={(_e, data) => formik.setFieldValue("logo", data.value)}
						onBlur={formik.handleBlur}
					/>
					<br />
					<Radio
						label="Con Logo Propio"
						name="tipo_logo"
						value="logoPropio"
						checked={formik.values.tipo_logo === "logoPropio"}
						onChange={(_e, data) => formik.setFieldValue("logo", data.value)}
						onBlur={formik.handleBlur}
					/>
					<br />
					<Radio
						label="Sin Logo"
						name="tipo_logo"
						value="sinLogo"
						checked={formik.values.tipo_logo === "noLogo"}
						onChange={(_e, data) => formik.setFieldValue("logo", data.value)}
						onBlur={formik.handleBlur}
					/>
				</Form.Field> */}

				<div>
					<Button
						type="button"
						onClick={formik.handleReset}
						id="secondary-button"
					>
						Limpiar
					</Button>
					<Button
						type="submit"
						onClick={formik.handleSubmit}
						id="primary-button"
					>
						Enviar
					</Button>
				</div>
			</Form>
			<div className="register__text">* Campos obligatorios</div>
			<div className="register__text">
				Cuando termine su registro, recibirá por correo un enlace para registrar
				su contraseña.
			</div>
			<Divider />
			<div className="register__register">
				<NavLink to="/">
					<Button type="button">Regresar a Iniciar sesión</Button>
				</NavLink>
			</div>
			<br />
			<br />
		</>
	);
};

export default Login;
