import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	perfil: {}
};

export const profileSlice = createSlice({
	name: 'profile',
	initialState,
	reducers: {
		setProfile: (state, action) => {
			state.perfil = action.payload;
		},
	},
});

// Action creators 
export const { setProfile } = profileSlice.actions;
