import { baseApi } from "../../api/baseApi.js";
import { setError, setIsLoading, setMessage } from "../status/statusSlice.js";
import { setDetails, setList } from "./studiesSlice.js";

export const createStudy = (
  asesor,
  nombre,
  apellido_paterno,
  apellido_materno,
  rfc,
  curp,
  nss,
  entidades_federativas,
  tipo_estudio
) => {
  return async (dispatch) => {
    dispatch(setIsLoading(true));
    dispatch(setError(null));
    dispatch(setMessage(null));
    // const entidades_federativas = JSON.stringify(entidades)
    try {
      const response = await baseApi.post("/studies", {
        asesor,
        nombre,
        apellido_paterno,
        apellido_materno,
        rfc,
        curp,
        nss,
        entidades_federativas,
        tipo_estudio,
      });
      if (!response.data) {
        dispatch(setError("Error al registrar el estudio"));
        return;
      }
      dispatch(setError(null));
    } catch (error) {
      dispatch(setError(error.response.data));
    }
    dispatch(setIsLoading(false));
    dispatch(setMessage("Estudio registrado exitosamente"));
  };
};

export const getList = (asesor, status) => {
  return async (dispatch) => {
    dispatch(setIsLoading(true));
    dispatch(setError(null));
    try {
      const response = await baseApi.get(
        `/studies/getList?asesor=${asesor}&status=${status}`
      );
      if (!response.data) {
        dispatch(setError("Error al obtener lista de estudios"));
        return;
      }
      dispatch(setList(response.data.res));
      dispatch(setError(null));
    } catch (error) {
      dispatch(setError(error.response.data));
    }
    dispatch(setIsLoading(false));
  };
};

export const getDetails = (id) => {
  return async (dispatch) => {
    dispatch(setIsLoading(true));
    dispatch(setError(null));
    try {
      const response = await baseApi.get(`/studies/${id}`);
      if (!response.data) {
        dispatch(setError("Error al obtener detalles del estudio"));
        return;
      }
      dispatch(setDetails(response.data[0]));
      dispatch(setError(null));
    } catch (error) {
      dispatch(setError(error.response.data));
    }
    dispatch(setIsLoading(false));
  };
};

export const changeStatus = (id, status) => {
  return async (dispatch) => {
    dispatch(setIsLoading(true));
    dispatch(setError(null));
    dispatch(setMessage(null));
    try {
      const response = await baseApi.post(`/studies/changeStatus`, {
        id,
        status,
      });
      if (!response.data) {
        dispatch(setError("Error al cambiar el status del estudio"));
        return;
      }
      dispatch(setError(null));
    } catch (error) {
      dispatch(setError(error.response.data));
    }
    dispatch(setIsLoading(false));
    dispatch(setMessage("Status del estudio cambiado exitosamente"));
  };
};

export const deleteStudy = (id) => {
  return async (dispatch) => {
    dispatch(setIsLoading(true));
    dispatch(setError(null));
    dispatch(setMessage(null));
    try {
      const response = await baseApi.delete(`/studies/${id}`);
      if (!response.data) {
        dispatch(setError("Error al eliminar el estudio"));
        return;
      }
      dispatch(setError(null));
    } catch (error) {
      dispatch(setError(error.response.data));
    }
    dispatch(setIsLoading(false));
    dispatch(setMessage("Estudio eliminado exitosamente"));
  };
};

export const uploadFile = (id, tipoImagen, selectedFile) => {
  return async (dispatch) => {
    dispatch(setIsLoading(true));
    dispatch(setError(null));
    dispatch(setMessage(null));
    try {
      const response = await baseApi.post(`/studies/uploadFile`, {
        id,
        tipoImagen,
        selectedFile,
      });
      if (!response.data) {
        dispatch(setError("Error al subir el archivo"));
        return;
      }
      dispatch(setError(null));
    } catch (error) {
      dispatch(setError(error.response.data));
    }
    dispatch(setIsLoading(false));
    dispatch(setMessage("Archivo subido exitosamente"));
  };
};
