import { useEffect } from "react";
import { Button, Table } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { useGetFacturacionQuery } from "../../../store/facturacion/FacturacionApi";
import { setFacturacion } from "../../../store/facturacion";

const ListaFacturacion = ({ user_id, setDetalleId, setEdit }) => {
	const dispatch = useDispatch();
	const { data: facturacion = [], isLoading } = useGetFacturacionQuery(
		user_id,
		{
			refetchOnMountOrArgChange: true,
		}
	);

	useEffect(() => {
		if (!isLoading) dispatch(setFacturacion(facturacion));
	}, [isLoading, dispatch, facturacion]);

	return (
		<>
			<Table striped>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>RFC</Table.HeaderCell>
						<Table.HeaderCell>Razón social</Table.HeaderCell>
						<Table.HeaderCell>Detalles</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{facturacion.map((factura) => (
						<Table.Row key={factura.id}>
							<Table.Cell>{factura.rfc}</Table.Cell>
							<Table.Cell>{factura.razon_social}</Table.Cell>
							<Table.Cell>
								<Button
									className="secondary-button"
									onClick={() => setDetalleId(factura.id)}
								>
									Detalles
								</Button>
							</Table.Cell>
						</Table.Row>
					))}
				</Table.Body>
			</Table>
			<div id="botonera">
				<Button
					type="button"
					id="secondary-button"
					onClick={() => setEdit(true)}
				>
					Nuevo
				</Button>
			</div>
		</>
	);
};

export default ListaFacturacion;
