import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseurl } from "../../constants/constants";

export const PpsApi = createApi({
  reducerPath: "ppsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseurl}`,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["pp"],
  endpoints: (builder) => ({
    getList: builder.query({
      query: () => `/pp/list`,
      method: "GET",
      providesTags: ["pp"],
    }),
    getDetails: builder.query({
      query: (id) => {
        // transform id to int
        id = parseInt(id);
        return {
          url: `/pp/${id}`,
          method: "GET",
        };
      },
    }),
    create: builder.mutation({
      query: (body) => ({
        url: `/pp/create`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["pp"],
    }),
    update: builder.mutation({
      query: (body) => ({
        url: `/pp/${body.id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["pp"],
    }),
    createOrUpdate: builder.mutation({
      query: (body) => ({
        url: `/pp/createOrUpdate`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["pp"],
    }),
  }),
});

export const {
  useGetListQuery,
  useGetDetailsQuery,
  useCreateMutation,
  useUpdateMutation,
  useCreateOrUpdateMutation,
} = PpsApi;
