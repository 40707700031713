import { Button, Icon, Popup, Table } from "semantic-ui-react";
import { NavLink, useNavigate } from "react-router-dom";

import "./ListaEstudios.scss";
import { useGetListQuery } from "../../../../store/studies/StudiesApi";
import { urlBaseEstudios } from "../../../../constants/constants";

const ListaEstudios = ({ status, user_id }) => {
	const navigate = useNavigate();

	const args = { asesor: parseInt(user_id, 10), status: parseInt(status, 10) };
	const { data: lista = [] } = useGetListQuery(args, {
		refetchOnMountOrArgChange: true,
	});

	return (
		<>
			<div className="subheader">Lista</div>
			<Table className="table">
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>ID</Table.HeaderCell>
						<Table.HeaderCell>Nombre</Table.HeaderCell>
						<Table.HeaderCell>Acción</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{lista && lista.res
						? lista?.res?.map((item) => {
								return (
									<Table.Row key={item.id}>
										<Table.Cell>{item.id}</Table.Cell>
										<Table.Cell>
											{item.nombre} {item.apellido_paterno}{" "}
											{item.apellido_materno}
										</Table.Cell>
										<Table.Cell>
											<span>
												<NavLink
													to={`/estudios/captura/detalles?id=${item.id}`}
												>
													<Popup
														content="Ir a los detalles de este estudio"
														trigger={<Button>Detalles</Button>}
													/>
												</NavLink>
												{item.status === 4 && (
													<Popup
														content="Copia un link para compartir con el cliente"
														trigger={
															<Icon
																name="copy outline"
																color="grey"
																size="large"
															/>
														}
													/>
												)}
												{item.status === 4 && (
													<Popup
														content="Manda el acceso al estudio a tu correo electrónico"
														trigger={
															<Icon
																name="envelope outline"
																color="grey"
																size="large"
															/>
														}
													/>
												)}
												{item.status === 4 && (
													<Popup
														content="Solicita un reproceso"
														trigger={
															<Icon name="recycle" color="grey" size="large" />
														}
													/>
												)}
											</span>
										</Table.Cell>
									</Table.Row>
								);
						  })
						: null}
				</Table.Body>
			</Table>

			<div id="buttons">
				<Button
					className="button"
					onClick={() => navigate(`${urlBaseEstudios}/dashboard`)}
				>
					Regresar
				</Button>
			</div>
		</>
	);
};

export default ListaEstudios;
