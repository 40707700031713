import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import {  Button, Container,  Table } from "semantic-ui-react";
import { useGetDetailsQuery } from "../../../../store/pps/PpsApi";
import { camposPPs, textosPPs, urlBaseAdministracion } from "../../../../constants/constants";

import "./DetallePP.scss";

const DetallePP = () => {
	const [searchParams] = useSearchParams();
	const id = searchParams.get("id");
	const navigate = useNavigate();

  const { data: pp_data = [] } = useGetDetailsQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  const replaceValueWithText = (value) => {
    const timestampRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/;
    if (timestampRegex.test(value)) {
      return value.split("T")[0];
    }
    const text = textosPPs.find((item) => item.value === value);
    return text ? text.text : value;
  };

  const handleRegresar = () => {
    navigate(`${urlBaseAdministracion}/detalles?id=${id}`);
  };

	return (
		<Container fluid className="container">
			<div className="header">Detalle del PP</div>
			<div className="detalle-estudio">
				<Table striped>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>Campo</Table.HeaderCell>
							<Table.HeaderCell>Valor</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
          <Table.Body>

            {camposPPs.map((campo) => (
              <Table.Row key={campo.value}>
                <Table.Cell key={`${campo.value}-left`}>{campo.text}</Table.Cell>
                <Table.Cell key={`${campo.value}-right`}>{replaceValueWithText(pp_data[0]?.[campo.value])}</Table.Cell>
              </Table.Row>
            ))}            
            
					</Table.Body>
				</Table>
			</div>

      <br />

      <div id="botonera1">
        <NavLink to={`/administracion/EditaPP?id=${id}`}>
					<Button>Editar PP</Button>
				</NavLink>

        <button onClick={handleRegresar} className="ui button">
					Regresar
				</button>
      </div>
      
      <br />
		</Container>
	);
};

export default DetallePP;
