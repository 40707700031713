import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { baseurl } from '../../constants/constants'

export const FacturacionApi = createApi({
	reducerPath: 'facturacionApi',
	baseQuery: fetchBaseQuery({
		baseUrl: `${baseurl}`,
		prepareHeaders: (headers, { getState }) => {
			const token = getState().auth.token;
			if (token) {
				headers.set('Authorization', `Bearer ${token}`);
			}
			return headers;
		}
	}),
	endpoints: (builder) => ({
		getFactura: builder.query({
			query: (id) => `/invoice/${id}`,
		}),
		getFacturacion: builder.query({
			query: (id) => `/invoices/${id}`,
		}),
		createFactura: builder.mutation({
			query: (data) => ({
				url: '/invoices/',
				method: 'POST',
				body: data,
			}),
		}),
		updateFactura: builder.mutation({
			query: (body) => ({
				url: `/invoices/${body.detalleId}`,
				method: 'PUT',
				body,
			}),
		}),
		deleteFactura: builder.mutation({
			query: (id) => ({
				url: `/invoices/${id}`,
				method: 'DELETE',
			}),
		}),
		uploadFacturacionFile: builder.mutation({
			query: (body) => {
				const formData = new FormData();
				formData.append("image", body.image);
				formData.append("id", body.id);
				formData.append("tipoImagen", body.tipoImagen);
				return {
					url: `/invoices/uploadFacturacionFile`,
					method: "POST",
					body: formData,
				}
			}
		}),
	}),
})

export const {
	useGetFacturaQuery,
	useGetFacturacionQuery,
	useCreateFacturaMutation,
	useUpdateFacturaMutation,
	useDeleteFacturaMutation,
	useUploadFacturacionFileMutation,
} = FacturacionApi

