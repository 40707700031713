import { Button, Container, Divider } from "semantic-ui-react";
// import Lista from "../Administracion/Lista/Lista";
import "./Dashboard.scss";
import { urlBaseAdministracion } from "../../constants/constants";
import { NavLink } from "react-router-dom";

const Dashboard = () => {
  return (
    <Container fluid>
      <div className="dashboard">
        <div className="title">Administración</div>
        <div id="buttons">
          <NavLink to={`${urlBaseAdministracion}/lista/1`}>
            <Button className="button">Captura</Button>
          </NavLink>
          <NavLink to={`${urlBaseAdministracion}/lista/2`}>
            <Button className="button">Validación</Button>
          </NavLink>
          <NavLink to={`${urlBaseAdministracion}/lista/3`}>
            <Button className="button">Proceso</Button>
          </NavLink>
          <NavLink to={`${urlBaseAdministracion}/lista/4`}>
            <Button className="button">Completados</Button>
          </NavLink>
          <NavLink to={`${urlBaseAdministracion}/lista/5`}>
            <Button className="button">Reprocesos</Button>
          </NavLink>
        </div>
        {/* <Lista /> */}
        <Divider />
      </div>
    </Container>
  );
};

export default Dashboard;
