import { useParams } from "react-router-dom";
import { Button, Container, Popup, Table } from "semantic-ui-react";
import { NavLink } from "react-router-dom";

import "./Lista.scss";
import {
  useGetAllQuery,
  useGetStatusesQuery,
} from "../../../store/studies/StudiesApi";

const Lista = () => {
  const { id } = useParams();

  const { data: lista = [] } = useGetAllQuery(id, {
    refetchOnMountOrArgChange: true,
  });
  const { data: statuses = [] } = useGetStatusesQuery();

  return (
    <Container>
      <div className="title">Administración</div>
      <br />
      {statuses.res && (
        <div className="header">{statuses?.res[id - 1]?.descripcion}</div>
      )}
      <br />
      <Table selectable celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Cliente</Table.HeaderCell>
            <Table.HeaderCell>Asesor</Table.HeaderCell>
            {/* <Table.HeaderCell>Status</Table.HeaderCell> */}
            <Table.HeaderCell>Acción</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {lista?.map((item) => {
            return (
              <Table.Row key={item.id}>
                <Table.Cell>{item.id}</Table.Cell>
                <Table.Cell>
                  {item.nombre} {item.apellido_paterno} {item.apellido_materno}
                </Table.Cell>
                <Table.Cell>
                  {item.nombre_asesor} {item.apellido_paterno_asesor}{" "}
                  {item.apellido_materno_asesor}
                </Table.Cell>
                {/* <Table.Cell>
                  {statuses?.res?.map((status) => {
                    if (status.id === item.status) {
                      return status.descripcion;
                    } else {
                      return "";
                    }
                  })}
                </Table.Cell> */}
                <Table.Cell>
                  <NavLink to={`/administracion/detalles?id=${item.id}`}>
                    <Popup
                      content="Ir a los detalles de este estudio"
                      trigger={<Button className="thisbutton">Detalles</Button>}
                    />
                  </NavLink>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      <br />
      <div id="botonera1">
        <NavLink to={`/administracion/dashboard`}>
          <Button>Regresar</Button>
        </NavLink>
      </div>
      <br />
      <br />
    </Container>
  );
};

export default Lista;
