import "./Perfil.scss";
import { useSelector } from "react-redux";
import DetallesPerfil from "./DetallesPerfil";
import { useState } from "react";
import EditarPerfil from "./EditarPerfil";
import { useGetProfileQuery } from "../../../store/profile/ProfileApi";

const Perfil = () => {
	const [edit, setEdit] = useState(false);
	const user_id = useSelector((state) => state.auth.id);

	const { data: profile = "" } = useGetProfileQuery(user_id, {
		refetchOnMountOrArgChange: true,
	});

	return (
		<div className="container">
			<div className="perfil-table-container">
				<div className="subheader">Perfil</div>

				{edit ? (
					<EditarPerfil profile={profile} setEdit={setEdit} />
				) : (
					<DetallesPerfil setEdit={setEdit} />
				)}
			</div>
		</div>
	);
};

export default Perfil;
