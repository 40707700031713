import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import {
  Button,
  Container,
  Dropdown,
  Modal,
  Table,
  Confirm,
  Input,
} from "semantic-ui-react";

import {
  baseurl,
  enviroment,
  textosCambioNivel,
  tiposImagenesCaptura,
  urlBaseCaptura,
  urlBaseEstudios,
} from "../../../../constants/constants";
import {
  useAddLogsMutation,
  useChangeStatusMutation,
  useGetDetailsQuery,
  useGetLogsQuery,
  useLazyGetLFilesQuery,
  useUploadFileMutation,
} from "../../../../store/studies/StudiesApi";
import { toast } from "react-toastify";
import moment from "moment-timezone";

import "./DetalleEstudio.scss";

const DetalleEstudio = () => {
  const [abrirModal, setAbrirModal] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [tipoImagen, setTipoImagen] = useState("");
  const [openNotes, setOpenNotes] = useState(false);
  const [noteText, setNoteText] = useState("");
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const navigate = useNavigate();
  const { data: estudio = [] } = useGetDetailsQuery(id, {
    refetchOnMountOrArgChange: true,
  });
  const [changeStatus] = useChangeStatusMutation();
  const [uploadFile] = useUploadFileMutation();
  const [addLogs] = useAddLogsMutation();
  const user_id = useSelector((state) => state.auth.id);
  const role = useSelector((state) => state.auth.role);
  const { data: logs } = useGetLogsQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  if (enviroment === "dev") console.log("estudio", estudio);

  const [getLFiles] = useLazyGetLFilesQuery();

  const handleEditar = () => {
    navigate(`${urlBaseEstudios}/editar?id=${id}`);
  };

  const handleAbrirModal = () => {
    setSelectedFile(null);
    setTipoImagen("");
    setAbrirModal(true);
  };

  const handleCancelUpload = () => {
    setAbrirModal(false);
  };

  const handleImageTypeChange = (_e, data) => {
    setTipoImagen(data.value);
  };

  const onFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const notifySaved = useCallback(() => {
    toast.success("Archivo guardado");
  }, []);

  const handleFileUpload = () => {
    setAbrirModal(false);
    const fileType = selectedFile.type;
    if (
      fileType === "application/pdf" ||
      fileType === "image/jpeg" ||
      fileType === "image/png" ||
      fileType === "image/gif"
    ) {
      uploadFile({
        id,
        tipoImagen,
        image: selectedFile,
      }).then(() => {
        notifySaved();
      });
      setSelectedFile(null);
    } else {
      alert("Seleccione una imágen válida");
    }
    setSelectedFile(null);
  };

  // eslint-disable-next-line no-unused-vars
  const deleteEstudio = () => {
    changeStatus({ id, status: 99 });
    toast.success(`Estudio eliminado`);
    setTimeout(() => {
      navigate(urlBaseCaptura);
    }, 1000);
  };

  const handleEnviarValidar = () => {
    changeStatus({ id, status: 2 });
    toast.success("Estudio enviado a validación");
    setTimeout(() => {
      navigate(urlBaseCaptura);
    }, 1000);
  };

  const handleEliminar = () => {
    if (enviroment === "dev") console.log("eliminar");
    changeStatus({ id, status: 99 });
    toast.warning("Estudio eliminado");
    setTimeout(() => {
      navigate(urlBaseCaptura);
    }, 1000);
    setDeleteConfirm(false);
  };

  const addBlankSpace = (str) => {
    return str?.replace(/,/g, ", ");
  };

  const handleRegresar = () => {
    if (role === 1) {
      const status = estudio[0]?.status;
      if (status === 1) {
        navigate(`${urlBaseEstudios}/captura`);
      } else if (status === 2) {
        navigate(`${urlBaseEstudios}/validacion`);
      } else if (status === 3) {
        navigate(`${urlBaseEstudios}/enproceso`);
      } else if (status === 4) {
        navigate(`${urlBaseEstudios}/completados`);
      } else if (status === 5) {
        navigate(`${urlBaseEstudios}/reprocesos`);
      } else {
        navigate(`${urlBaseEstudios}`);
      }
    } else {
      navigate(`/administracion/dashboard`);
    }
  };

  const handleDownloadImages = async () => {
    getLFiles(id).then((res) => {
      const link1 = baseurl + "/" + res.data;
      const link = document.createElement("a");
      link.href = baseurl + "/" + res.data;
      link.setAttribute("download", link1);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  };
  const handleEnviarOtroNivel = (text, next) => {
    if (enviroment === "dev") console.log(id, text, next);
    changeStatus({ id, status: next });
    toast.success(
      textosCambioNivel.filter((item) => item.id === text)[0].toast
    );
    setTimeout(() => {
      navigate(`/administracion/dashboard`);
    }, 1000);
  };

  const cleanupDatetime = (datetime) => {
    datetime = moment(datetime).tz("America/Mexico_City").format();
    datetime = datetime.substring(0, datetime.length - 6);
    return datetime?.replace("T", " ").replace(".000Z", "");
  };

  const BotonCambioNivel = (text, next) => {
    return (
      <Button
        className="boton-cambiar-nivel"
        onClick={() => handleEnviarOtroNivel(text, next)}
      >
        {textosCambioNivel.filter((item) => item.id === text)[0].text}
      </Button>
    );
  };

  const BotonDetallePP = () => {
    return (
      <NavLink to={`/administracion/DetallePP?id=${id}`}>
        <Button>Detalle PP</Button>
      </NavLink>
    );
  };

  const handleNewNote = () => {
    setOpenNotes(true);
  };

  const handleNoteChange = (e) => {
    setNoteText(e.target.value);
  };

  const handleAddNote = () => {
    if (noteText !== "") {
      addLogs({
        estudios_id: id,
        notes: noteText,
        user_id: user_id,
      });
      setNoteText("");
      setOpenNotes(false);
    }
  };

  const ModalCapturaImagen = () => (
    <Modal
      open={abrirModal}
      size="tiny"
      className="modal"
      closeOnDimmerClick={false}
    >
      <Modal.Header>Captura de imagen</Modal.Header>
      <Modal.Content>
        <Dropdown
          placeholder="Selecciona el tipo de imagen"
          onChange={handleImageTypeChange}
          selection
          options={tiposImagenesCaptura}
          value={tipoImagen}
          className="dropdown"
        />
        <div>
          <label
            htmlFor="hidden-new-file"
            className="ui icon button input-file"
          >
            <i className="cloud icon"></i> Seleccionar archivo
          </label>
          <input
            type="file"
            id="hidden-new-file"
            className="input-button"
            onChange={onFileChange}
          />
        </div>
        <div className="botonera">
          <Button className="ui button" onClick={handleCancelUpload}>
            Cancelar
          </Button>
          <Button
            className={`ui button ${
              selectedFile && tipoImagen ? "upload-button" : ""
            }`}
            id={`${
              selectedFile && tipoImagen ? "upload-button" : "disabled-button"
            }`}
            onClick={handleFileUpload}
            disabled={!selectedFile || !tipoImagen}
          >
            Subir
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );

  return (
    <Container fluid className="container">
      <div className="header">Detalles</div>
      <div className="detalle-estudio">
        <Table striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Campo</Table.HeaderCell>
              <Table.HeaderCell>Valor</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row key={"tipo_estudio"}>
              <Table.Cell>Tipo de estudio</Table.Cell>
              <Table.Cell>{estudio[0]?.tipo_estudio}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Nombre</Table.Cell>
              <Table.Cell>{estudio[0]?.nombre}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Apellido Paterno</Table.Cell>
              <Table.Cell>{estudio[0]?.apellido_paterno}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Apellido Materno</Table.Cell>
              <Table.Cell>{estudio[0]?.apellido_materno}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>RFC</Table.Cell>
              <Table.Cell>{estudio[0]?.rfc}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>CURP</Table.Cell>
              <Table.Cell>{estudio[0]?.curp}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>NSS</Table.Cell>
              <Table.Cell>{estudio[0]?.nss}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Entidades federativas</Table.Cell>
              <Table.Cell>
                {addBlankSpace(estudio[0]?.entidades_federativas)}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
      <br />

      <div className="detalle-estudio">
        <Table striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Notas y bitácora</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {logs?.map((log) => (
              <Table.Row key={log.id}>
                <Table.Cell>
                  <b>
                    {cleanupDatetime(log.created_at)} - {log.nombre_usuario}{" "}
                    {log.apellido_paterno_usuario}{" "}
                    {log.apellido_materno_usuario}:
                  </b>{" "}
                  {log.notes}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
      <br />

      {openNotes && (
        <>
          <div className="detalle-estudio">
            <Table striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Agregar nota</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row key={999}>
                  <Table.Cell>
                    <Input
                      onChange={(e) => handleNoteChange(e)}
                      className="input-logs"
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row key={"button"}>
                  <Table.Cell className="buttons-logs">
                    <Button onClick={() => setOpenNotes(false)}>
                      Cancelar
                    </Button>
                    <Button onClick={handleAddNote}>Agregar nota</Button>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
          <br />
        </>
      )}

      <ModalCapturaImagen />

      <Confirm
        open={deleteConfirm}
        onCancel={() => setDeleteConfirm(false)}
        onConfirm={() => handleEliminar()}
        content="¿Estás seguro de eliminar el estudio?"
        cancelButton="Cancelar"
        confirmButton="Eliminar"
      />

      <div id="botonera1">
        <Button onClick={handleNewNote}>Nueva nota en bitácora</Button>
      </div>
      <br />

      <div id="botonera1">
        {role === 1 && (
          <Button onClick={handleAbrirModal}>Captura de imágenes</Button>
        )}
        <NavLink to={`/estudios/captura/verImagenes?id=${id}&tipo=estudio`}>
          <Button>Ver imágenes</Button>
        </NavLink>
        <Button onClick={handleDownloadImages}>Descargar imágenes</Button>
      </div>
      <br />

      {role === 1 && (
        <div id="botonera2">
          <button onClick={() => handleEditar()} className="ui button">
            Editar
          </button>
          {estudio[0]?.status === 1 && (
            <>
              <button
                onClick={() => setDeleteConfirm(true)}
                className="ui button"
              >
                Eliminar
              </button>
              <button
                onClick={() => handleEnviarValidar()}
                className="ui button"
              >
                Enviar a Validación
              </button>
            </>
          )}
        </div>
      )}

      {role >= 2 && estudio[0]?.status === 2 && (
        <div id="botonera2">
          {BotonCambioNivel(5, 1)}
          {BotonCambioNivel(2, 3)}
          {BotonDetallePP()}
        </div>
      )}

      {role >= 2 && estudio[0]?.status === 3 && (
        <div id="botonera2">
          {BotonCambioNivel(6, 2)}
          {BotonCambioNivel(3, 4)}
          {BotonDetallePP()}
        </div>
      )}

      {role >= 2 && estudio[0]?.status === 4 && (
        <div id="botonera2">
          <button
            onClick={() => handleEnviarOtroNivel(4)}
            className="ui button"
          >
            {textosCambioNivel.filter((item) => item.id === 4)[0].text}
          </button>
        </div>
      )}

      {role >= 2 && estudio[0]?.status === 5 && (
        <div id="botonera2">
          <button
            onClick={() => handleEnviarOtroNivel(3)}
            className="ui button"
          >
            {textosCambioNivel.filter((item) => item.id === 3)[0].text}
          </button>
        </div>
      )}

      <br />

      <div id="botonera3">
        <button onClick={handleRegresar} className="ui button">
          Regresar
        </button>
      </div>
      <br />
      <br />
      <br />
    </Container>
  );
};

export default DetalleEstudio;
