import { useSelector } from "react-redux";
import { Container } from "semantic-ui-react";
import ListaEstudios from "../../Shared/components/ListaEstudios/ListaEstudios";

import "./Enproceso.scss";

const Enproceso = () => {
	const user_id = parseInt(
		useSelector((state) => state.auth.id),
		10
	);
	return (
		<Container>
			<div className="header">Estudios - En proceso</div>
			<div className="captura">
				<ListaEstudios status="3" user_id={user_id} />
			</div>
		</Container>
	);
};

export default Enproceso;
