import { useState } from "react";
import ListaFacturacion from "./ListaFacturacion";
import DetallesFacturacion from "./DetallesFacturacion";
import EditarFacturacion from "./EditarFacturacion";
import { useSelector } from "react-redux";

const Facturacion = () => {
	const user_id = useSelector((state) => state.auth.id);
	const [detalleId, setDetalleId] = useState("");
	const [edit, setEdit] = useState(false);

	return (
		<div className="container">
			<div className="perfil-table-container">
				<div className="subheader">Facturación</div>
				{edit ? (
					<EditarFacturacion
						user_id={user_id}
						detalleId={detalleId}
						setEdit={setEdit}
					/>
				) : detalleId ? (
					<DetallesFacturacion
						detalleId={detalleId}
						setDetalleId={setDetalleId}
						setEdit={setEdit}
					/>
				) : (
					<ListaFacturacion
						user_id={user_id}
						setDetalleId={setDetalleId}
						setEdit={setEdit}
					/>
				)}
			</div>
		</div>
	);
};

export default Facturacion;
