import React from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";

import Login from "../features/Auth/components/Login/Login";

import Captura from "../features/Estudios/Captura/Captura";
import Validacion from "../features/Estudios/Validacion/Validacion";
import Enproceso from "../features/Estudios/Enproceso/Enproceso";
import Reprocesos from "../features/Estudios/Reprocesos/Reprocesos";
import Completados from "../features/Estudios/Completados/Completados";
import Dashboard from "../features/Estudios/Dashboard";
import NuevoEstudio from "../features/Shared/components/NuevoEstudio/NuevoEstudio";
import ListaEstudios from "../features/Shared/components/ListaEstudios/ListaEstudios";
import DetalleEstudio from "../features/Shared/components/DetalleEstudio/DetalleEstudio";
import EditaEstudio from "../features/Shared/components/EditaEstudio/EditaEstudio";
import Home from "../features/Shared/components/Home/Home";
import VerImagenes from "../features/Shared/components/VerImagenes/VerImagenes.jsx";

export const RouterEstudios = () => {
  let authState = useSelector((state) => state.auth.authState);
  return (
    <Routes>
      {authState ? (
        <>
          <Route element={<ProtectedRoute />}>
            <Route path="*" element={<Home />} />
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/captura" element={<Captura />} />
            <Route exact path="/validacion" element={<Validacion />} />
            <Route exact path="/enproceso" element={<Enproceso />} />
            <Route exact path="/completados" element={<Completados />} />
            <Route exact path="/reprocesos" element={<Reprocesos />} />
            <Route
              exact
              path="/captura/verImagenes"
              element={<VerImagenes />}
            />

            <Route exact path="/captura/nuevo" element={<NuevoEstudio />} />
            <Route exact path="/captura/lista" element={<ListaEstudios />} />

            <Route
              exact
              path="/captura/detalles"
              element={<DetalleEstudio />}
            />
            <Route exact path={`/editar`} element={<EditaEstudio />} />
          </Route>
        </>
      ) : (
        <>
          <Route index element={<Login />} />
          <Route path="*" element={<Login />} />
        </>
      )}
    </Routes>
  );
};
