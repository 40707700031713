import { configureStore } from "@reduxjs/toolkit";
import { authSlice } from "./auth";
import { statusSlice } from "./status";
import { studiesSlice } from "./studies";
import { profileSlice } from "./profile";
import { facturacionSlice } from "./facturacion";
import { FacturacionApi } from "./facturacion/FacturacionApi";
import { ProfileApi } from "./profile/ProfileApi";
import { StudiesApi } from "./studies/StudiesApi";
import { PpsApi } from "./pps/PpsApi";

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    status: statusSlice.reducer,
    studies: studiesSlice.reducer,
    profile: profileSlice.reducer,
    facturacion: facturacionSlice.reducer,
    pps: PpsApi.reducer,

    [FacturacionApi.reducerPath]: FacturacionApi.reducer,
    [ProfileApi.reducerPath]: ProfileApi.reducer,
    [StudiesApi.reducerPath]: StudiesApi.reducer,
    [PpsApi.reducerPath]: PpsApi.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(FacturacionApi.middleware)
      .concat(ProfileApi.middleware)
      .concat(StudiesApi.middleware)
      .concat(PpsApi.middleware),
});
