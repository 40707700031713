import { useCallback, useState } from "react";
import { NavLink } from "react-router-dom";
import { Button, Dropdown, Modal, Table } from "semantic-ui-react";

import "./Facturacion.scss";
import {
	useGetFacturaQuery,
	useUploadFacturacionFileMutation,
} from "../../../store/facturacion/FacturacionApi";
import { toast } from "react-toastify";

const tiposImagenesCaptura = [
	{
		text: "Constancia de situación fiscal",
		value: "constancia_situacion_fiscal",
	},
];

const DetallesFacturacion = ({ detalleId, setDetalleId, setEdit }) => {
	const [abrirModal, setAbrirModal] = useState(false);
	const [selectedFile, setSelectedFile] = useState(null);
	const [tipoImagen, setTipoImagen] = useState("");
	const [uploadFacturacionFile] = useUploadFacturacionFileMutation();

	const { data: fact = [] } = useGetFacturaQuery(detalleId, {
		refetchOnMountOrArgChange: true,
	});

	const handleAbrirModal = () => {
		setSelectedFile(null);
		setTipoImagen("");
		setAbrirModal(true);
	};

	const handleImageTypeChange = (_e, data) => {
		setTipoImagen(data.value);
	};

	const onFileChange = (e) => {
		setSelectedFile(e.target.files[0]);
	};

	const handleCancelUpload = () => {
		setAbrirModal(false);
	};

	const notifySaved = useCallback(() => {
		toast.success("Archivo guardado");
	}, []);

	const handleFileUpload = () => {
		setAbrirModal(false);
		const fileType = selectedFile.type;
		if (
			fileType === "application/pdf" ||
			fileType === "image/jpeg" ||
			fileType === "image/png" ||
			fileType === "image/gif"
		) {
			uploadFacturacionFile({
				id: detalleId,
				tipoImagen,
				image: selectedFile,
			}).then(() => {
				notifySaved();
			});
			setSelectedFile(null);
		} else {
			alert("Seleccione una imágen válida");
		}
		setSelectedFile(null);
	};

	const ModalCapturaImagen = () => (
		<Modal
			open={abrirModal}
			size="tiny"
			className="modal"
			closeOnDimmerClick={false}
		>
			<Modal.Header>Captura de imagen</Modal.Header>
			<Modal.Content>
				<Dropdown
					placeholder="Selecciona el tipo de imagen"
					onChange={handleImageTypeChange}
					selection
					options={tiposImagenesCaptura}
					value={tipoImagen}
					className="dropdown"
				/>
				<div>
					<label
						htmlFor="hidden-new-file"
						className="ui icon button input-file"
					>
						<i className="cloud icon"></i> Seleccionar archivo
					</label>
					<input
						type="file"
						id="hidden-new-file"
						className="input-button"
						onChange={onFileChange}
					/>
				</div>
				<div className="botonera">
					<Button className="ui button" onClick={handleCancelUpload}>
						Cancelar
					</Button>
					<Button
						className={`ui button ${
							selectedFile && tipoImagen ? "upload-button" : ""
						}`}
						id={`${
							selectedFile && tipoImagen ? "upload-button" : "disabled-button"
						}`}
						onClick={handleFileUpload}
						disabled={!selectedFile || !tipoImagen}
					>
						Subir
					</Button>
				</div>
			</Modal.Content>
		</Modal>
	);

	return (
		<>
			<Table striped className="perfil-table">
				<Table.Body>
					<Table.Row>
						<Table.Cell>Tipo de persona</Table.Cell>
						<Table.Cell>{fact?.tipo_persona}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>RFC</Table.Cell>
						<Table.Cell>{fact?.rfc}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>Razón social</Table.Cell>
						<Table.Cell>{fact?.razon_social}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>Código postal</Table.Cell>
						<Table.Cell>{fact?.codigo_postal}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>Régimen fiscal</Table.Cell>
						<Table.Cell>{fact?.regimen_fiscal}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>Correo electrónico</Table.Cell>
						<Table.Cell>{fact?.email}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>Uso de CFDI</Table.Cell>
						<Table.Cell>{fact?.uso_cfdi}</Table.Cell>
					</Table.Row>
				</Table.Body>
			</Table>

			<div id="botonera1">
				<Button onClick={handleAbrirModal}>Captura de imágenes</Button>
				<NavLink
					to={`/configuracion/verImagenes?id=${detalleId}&tipo=facturacion`}
				>
					<Button>Ver imágenes</Button>
				</NavLink>
			</div>
			<br />
			<ModalCapturaImagen />

			<div id="botonera">
				<Button
					type="button"
					className="secondary-button"
					onClick={() => setEdit(true)}
				>
					Editar
				</Button>
				<Button
					type="button"
					className="primary-button"
					onClick={() => setDetalleId("")}
				>
					Regresar
				</Button>
			</div>
		</>
	);
};

export default DetallesFacturacion;
